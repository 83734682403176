<template>
  <main class="kb-main" id="kb-helpdesk">
    <lxp-main-header :show-back="true">
      <template v-slot:inner>
        <div class="header-column column-center">
          <span class="status-text text-yellow">
            {{items.ansYn === 'Y' ? '답변완료' : '처리중'}}
          </span>
        </div>
        <div class="header-column">
          <div v-if="items.lrnerId === sessionId" class="buttons">
            <button v-if="items.ansYn === 'N'" @click="updateInq(items.comInqSn)" class="kb-btn kb-btn-sm kb-btn-outline rounded-lg"><span class="text">수정</span></button>
            <button @click="deleteInq(items.comInqSn)" class="kb-btn kb-btn-sm kb-btn-outline rounded-lg"><span class="text">삭제</span></button>
          </div>
        </div>
      </template>

    </lxp-main-header>
    <div class="main-content main-component">
      <div class="board-view-container qna-view-container">
        <div class="view-header min-component view-header-snuggle">
          <h3 class="title">{{items.inqTitle}}
<!--            <i v-if="items.readYn !== 'Y'" class="icon-new"></i>-->
          </h3>
          <p class="subtitle"><span class="text">{{ items.deptNm }}  {{ items.jbgdNm}} {{items.jbpsNm}} {{items.lrnerNm}}</span>
            <span class="text">{{getDateFormat('yyyy.MM.dd hh:mm', items.regDt)}}</span></p>
        </div>
        <div class="view-body min-component">
          <div class="view-content" v-html="items.inqCn"></div>
          <div v-if="fileItems.length > 0" class="view-files">
            <a v-for="(item, idx) in fileItems" :key="idx" href="javascript:" @click="fileDown(item)" class="kb-btn kb-btn-sm kb-btn-secondary rounded-lg kb-btn-download"><i class="icon-download"></i>
              <span class="text">{{item.fileNm}}</span>
            </a>
          </div>
          <div v-if="items.ansYn === 'Y'" class="view-content" v-html="items.ansCn"></div>
          <div v-if="items.ansYn === 'Y'" class="view-meta">
            <span class="text">관리자</span>
            <span class="text">{{getDateFormat('yyyy.MM.dd hh:mm', items.mdfcnDt)}}</span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {ACT_DELETE_HELP_QNA, ACT_GET_HELP_FILE_ATCH_LIST, ACT_GET_HELP_QNA} from "@/store/modules/help/help";
import {getItem, getItems, isSuccess, lengthCheck, timestampToDateFormat, commonDownloadFile} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import {downloadAtch} from "@/assets/js/modules/help/help-common";
import {isInApp, isInAppIOS} from "@/assets/js/navigationUtils";
export default {
  name: 'HelpQnaView',
  components: {LxpMainHeader},
  setup(){
    const inqTitle = ref('연수시스템 점검 안내');
    const items = ref({});
    const fileItems = ref([]);

    const store = useStore();
    const route = useRoute();

    const showAttachments = ref(false);
    const sessionId = ref(store.state.auth.session.lrnerId);
    const {showMessage} = useAlert();

    onMounted(() => {
      getHelpQa();
    })

    const getHelpQa = () => {
      store.dispatch(`help/${ACT_GET_HELP_QNA}`, route.params.qnaSn
      ).then(res => {
        if (lengthCheck(res)) {
          items.value = getItem(res);
          getHelpQaFileAtchList();
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const getHelpQaFileAtchList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FILE_ATCH_LIST}`, {
        comInqSn: route.params.qnaSn
      }).then(res => {
        if (lengthCheck(res)) {
          fileItems.value = getItems(res);
        } else {
          fileItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    }

    const router = useRouter();
    const updateInq = (inqSn) => {
      router.push({path: '/help/qna/write', query:{'comInqSn': inqSn}});
    }

    const deleteInq = (comInqSn) => {
      store.dispatch(`help/${ACT_DELETE_HELP_QNA}`,
        comInqSn
      ).then(res => {
        if (isSuccess(res)) {
          showMessage("QA를 삭제했습니다.", router.go(-1));
        } else {
          showMessage("QA삭제 실패. <br> 관리자에게 문의해주세요.");
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const fileDown = (item) => {
      if(sessionId.value === items.value.lrnerId){
        if(isInApp && isInAppIOS){

          commonDownloadFile(item);

        }else{
          downloadAtch(item);
        }
      }
    }

    return {
      sessionId,
      inqTitle,
      items,
      fileItems,
      showAttachments,
      getDateFormat,
      updateInq,
      deleteInq,
      fileDown,
      downloadAtch,
    }
  }
};
</script>
<style type="text/css">
.view-content p{
  font: unset;
}
</style>